import React from 'react';
import Modal from 'react-bootstrap/Modal';
import GreenLineImage from '../../img/green-line.png';
import './messageModal.scss';

const MessageModal = (props) => {
    return (
        <Modal
            show={props.showModal}
            centered
            onHide={props.handleModalClose}        
            backdrop="static">
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                {props.children}</Modal.Body>
            <Modal.Footer>
                {
                    props.showCloseButton &&
                    <button className="btn" variant="secondary" onClick={props.handleModalClose}>OK</button>
                }
            </Modal.Footer>
      </Modal>
  );
};

export default MessageModal;