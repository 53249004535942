import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import MessageModal from '../../components/modals/messageModal';
import { useHistory } from 'react-router';

const SessionTimer = (props) => {

    const [sessionTimeOut, setSessionTimeOut] = useState(false);
    const history = useHistory();

    const handleOnIdle = event => {
        setSessionTimeOut(true);
        history.replace('/');
    };

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * (props.timeoutMinutes === 0 ? 1 : props.timeoutMinutes),
        onIdle: handleOnIdle,
        debounce: 500
    });

    const renderTimedOut = () => {
        if (sessionTimeOut) {
            return (
                <MessageModal
                    showModal={true}
                    showCloseButton={false}          
                    title="Oops, something seems to have gone wrong.">
                    <p>Time has run out on the screen, but not on your OpenSky Visa&reg; Gold Card.</p>
                    <p>Click the link in your email from OpenSky to come back.</p>
                </MessageModal>
            );
        }
        else {
            return null;
        }
    };

    return (
        <>
            {props.children}
            {renderTimedOut()}
        </>
    );
};

export default SessionTimer;