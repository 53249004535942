import React, { useEffect, useState, useRef } from 'react';
import LineImage from '../../img/line.png';
import ProductDetailImage from '../../img/product-detail-card.webp';
import ProductCardBaseImage from '../../img/card-base.png';
import IconSafetyWhiteImage from '../../img/icon-safety-white.svg';
import CreditBureausImage from '../../img/credit-bureaus.svg';
import logoDesktop from '../../img/logo-d.png';
import logoMobile from '../../img/logo-m.png';
import openSkyLogoWhite from '../../img/logo-opensky-no-padding-white.svg';
import AcceptForm from '../../components/acceptForm/acceptForm';
import OfferLocked from '../../components/offerLocked/offerLocked';
import MessageModal from '../../components/modals/messageModal';
import SessionTimer from '../../containers/sessionTimer/sessionTimer';
import NumberFormat from 'react-number-format';
import * as FullStory from '@fullstory/browser';
import './productDetail.scss';

const ProductDetail = (props) => {



    const formTopRef = props.formTopRef;


    const scrollToFormTop = () => {
        FullStory.event('GoldHeroAccept', {});
        formTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        formTopRef.current.focus();
    };

    return (
        <>                          
            <div className="product-detail-section-content">
                <div className="section-information">
                    <div className="flex-direction-reverse">
                     
                        <div className="padding-when-mobile">
                            <h1 className="desktop-md">
                                You&rsquo;re ready<br />to graduate to
                                <br /> OpenSky Gold
                            </h1>
                        </div>
                    </div>
                    <ul className="pdp-points">
                        <li>
                            <p className="intro-sub"><NumberFormat value={props.customerData.creditLimit} displayType={'text'} thousandSeparator={true} decimalScale={0} prefix={'$'} /> unsecured credit line with no security deposit</p>
                        </li>
                        <li>
                            <p className="intro-sub">
                                No credit check and no application
                            </p>
                        </li>
                        <li>
                            <p className="intro-sub">
                                Accept your offer in a few quick steps
                            </p>
                        </li>
                        <li>
                            <p className="intro-sub">
                                Earn up to 10% cash back on everyday purchases*
                            </p>
                        </li>
                    </ul>
                    <div className="vertical-center">
                    <button type="button" className="banner-get-started" onClick={() => scrollToFormTop()}>
                        Accept Today
                      
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductDetail
