import React, { useRef } from 'react';
import NavBar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import ProductDetailImage from '../../img/product-detail-card-no-trademark.svg';
import ProductCardBaseImage from '../../img/card-base.png';
import './error.scss'

const Error = (props) => {

    const formTopRef = useRef();
    
    return (
        <>
        <div className='sticky'>
                <NavBar formTopRef={formTopRef} fieldValidationErrors={[]} hideButton={true} />
        </div>
            <section className="page error">
                <section class="product-detail-section">
                    <div class="product-detail-section-content">
                        <img
                            src={ProductDetailImage}
                            alt=""
                            class="floating-card"
                        />
                        <div class="card-base-image">
                            <img src={ProductCardBaseImage} alt="" />
                        </div>
                        <div class="section-information">
                            <h2 className='drop-shadow'>Oops, we&rsquo;re sorry</h2>
                            <p className='drop-shadow'>
                                It looks like something went wrong on our side,<br />
                                can you come back a little later and try again?
                            </p>
                        </div>
                    </div>
                </section>

            </section>
            <Footer hideButton={true} />
        </>
    )
}

export default Error