import React, { useEffect, useState, useRef } from 'react';
import CardComparisonTable from '../comparisonTable/CardComparisonTable';

import CardFrontVectorImg from '../../img/Card-front-vector.png';
import CardShadow from '../../img/Card-Shadow.svg';
import CheckMark from '../../img/Checkmark.svg';
import './goldcarddetail.scss';
const GoldCardDetail = (props) => {
    
    return (
        <>
            <div className="container">
                
                <div className="row">
                    <div className="text-left goldcardDetail">
                        OpenSky Gold is different than your current card.
                    </div>
                    <p className="goldcardDetailSummary mt-1">
                        Unsecured cards don’t require a security deposit.  That means more spending power to build your credit faster.
                    </p>
                </div>
                
                
            </div>

        </>
    )
}

export default GoldCardDetail;