import React, { useState, useEffect } from 'react';
import SecurityApi from '../../api/securityApi';
import OffersApi from '../../api/offersApi';
import Landing from '../landing/landing';
import Unauthorized from '../../components/unauthorized/unauthorized';
import Expired from '../../components/expired/expired';
import ThankYou from '../../components/thankYou/thankYou';
import ContentLoading from '../../components/contentLoading/contentLoading';
import Error from '../../components/error/error';
import LoadingModal from '../../components/modals/loadingModal';

const Main = (props) => {

    const [content, setContent] = useState(5);
    const [customerData, setCustomerData] = useState(null);
    const [isOfferLocked, setIsOfferLocked] = useState(null);
    const [fieldValidationErrors, setFieldValidationErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const contentViews = Object.freeze({
        Landing: 0,
        Unauthorized: 1,
        Expired: 2,
        ThankYou: 3,
        Error: 4,
        Loading: 5
    });

    useEffect(() => {
        setIsLoading(true);
        getCustomerData()
            .then(response => {
                console.log("Customer Data", response);
                setCustomerData(response);
                getOfferState()
                    .then(response => {
                        if (response) {
                            setIsOfferLocked(false);
                            setIsLoading(false);
                            setContent(contentViews.Landing);

                        }
                    })
            })
    }, []);

    const handleError = (response) => {
        if (response.status === 400 || response.status === 422) {
            response.json()
                .then(responseData => {
                    setFieldValidationErrors(responseData);
                });
        } else if (response.status === 401 && response.headers.get('www-authenticate').includes("token expired")) {
            setContent(contentViews.Expired);
        } else if (response.status === 401) {
            setContent(contentViews.Unauthorized);
        } else if (response.status === 403) {
            setIsOfferLocked(true);
            setContent(contentViews.Landing);
        } else if (response.status === 409) {
            setContent(contentViews.ThankYou);
        } else {
            setContent(contentViews.Error);
        }
        setIsLoading(false);
    };

    const getCustomerData = () => {
        const api = new SecurityApi();
        return api.getCustomerData()
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    handleError(response);
                    return Promise.reject();
                }
            });
    };

    const getOfferState = () => {
        //const np = new Promise((resolve, reject) => {
        //    resolve(true);
        //});
        //return np;
        const api = new OffersApi();
        return api.getOfferState()
            .then(response => {
                if (response.ok) {
                    return response.ok;
                } else {
                    handleError(response);
                    return Promise.reject();
                }
            });
    };

    const acceptOffer = (formData) => {
        setIsLoading(true);
        const api = new OffersApi();
        return api.acceptOffer(formData)
            .then(response => {
                if (response.ok) {
                    setContent(contentViews.ThankYou);
                    setIsLoading(false);
                } else {
                    handleError(response);
                    return Promise.reject();
                }
            });
    };

    const renderContentView = () => {
        switch (content) {
            case contentViews.Unauthorized:
                return (<Unauthorized />);
            case contentViews.Expired:
                return (<Expired />);
            case contentViews.Error:
                return (<Error />);
            case contentViews.ThankYou:
                return (<ThankYou customerData={customerData} />);
            case contentViews.Landing:
                return (<Landing
                    customerData={customerData}
                    isOfferLocked={isOfferLocked}
                    fieldValidationErrors={fieldValidationErrors}
                    onFormSubmit={acceptOffer} />
                );
            case contentViews.Loading:
                return (<ContentLoading />);
        }
    };

    return (
        <>
            <LoadingModal showModal={isLoading} />
            {renderContentView()}
        </>
    );
};

export default Main;