import React, { useEffect, useState, useRef } from 'react';
import CheckMark from '../../img/Checkmark.svg';
const ComparisonCheckMark = (props) => {
    return (
        <div className="comparisonCheckmarkCellRowHeader">
            <div className="comparisonCheckmarkCellRow">
                <div className="comparisonCheckmarkCell">
                    <img src={CheckMark} alt="" />
                </div>
            </div>
        </div>
    )
}

export default ComparisonCheckMark;