import React, { useEffect, useState, useRef } from 'react';
const ComparisonCrossMark = (props) => {
    return (
        <div className="comparisonCheckmarkCellRowHeader">
            <div className="comparisonCheckmarkCellRow">
                <div className="comparisonCheckmarkCell">
                    <b>x</b>
                </div>
            </div>
        </div>
    )
}

export default ComparisonCrossMark;