import React, { useEffect, useState, useRef } from 'react';
import ComparisonDetail from './ComparisonDetail';
import ComparisonCrossMark from './ComparisonCrossMark';
import ComparisonCheckMark from './ComparisonCheckMark';
import ComparisonSimpleCheckMark from './ComparisonSimpleCheckMark';
import './CardComparison.scss';

const CardComparisonTable = (props) => {
    return (
        <>
            <div className="comparisonBox">
                <div className="comparisonBoxContainer">
                    <div className="comparisonHeader">
                        <ComparisonDetail name="No Security Deposit" />
                        <ComparisonDetail name="Fully Unsecured" />
                        <ComparisonDetail name="No Application Required" />
                        <ComparisonDetail name="No Credit Check" />
                        <ComparisonDetail name="Cash Back Rewards" />
                    </div>
                    <div className="comparisonColumnWrapper">
                        <div className="SecuredCardColumn">
                            <div className="goldCardCell">
                                <div className="OpenSkyComparisonWord">
                                    OpenSky
                                    <br />
                                    Secured Card
                                </div>
                            </div>
                            <div className="comparisonCheckmarkColumn blackWord">
                                <ComparisonCrossMark />
                                <ComparisonCrossMark />
                                <ComparisonCrossMark />
                                <ComparisonSimpleCheckMark />
                                <ComparisonSimpleCheckMark />
                            </div>
                        </div>
                        <div className="goldCardBlueColumn">
                            <div className="goldCardCell">
                                <div className="OpenSkyComparisonWord whiteWord">
                                    Opensky
                                    <br />
                                    Gold Card
                                </div>
                            </div>
                            <div className="comparisonCheckmarkColumn">
                                <ComparisonCheckMark />
                                <ComparisonCheckMark />
                                <ComparisonCheckMark />
                                <ComparisonCheckMark />
                                <ComparisonCheckMark />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                
        </>
    )
}

export default CardComparisonTable;