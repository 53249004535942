import React, { useEffect } from 'react';
import openSkyLogoWhite from '../../img/logo-opensky-no-padding-white-no-trademark.svg';
import openSkyLogo from '../../img/logo-opensky-no-trademark.svg';
import * as FullStory from '@fullstory/browser';
import './navbar.scss';
//import './condensed_navbar.css';
//import './_os-navbar.scss';

//TODO: remove this when possible
import '../../dotcom-app.css'

const NavBar = (props) => {
    const formTopRef = props.formTopRef;

    const scrollToFormTop = () => {
        FullStory.event('GoldBannerAccept', {});
        formTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        formTopRef.current.focus();
    };

    const updateScrollPosition = () => {        
        
        let onScrollSensitive = document.getElementsByClassName('onScroll');
        if (onScrollSensitive.length > 0) {
            for (let scrollSensitive of onScrollSensitive) {                
                scrollSensitive.dataset.scroll = window.scrollY;
            }
        }
    };


    useEffect(() => {
        document.addEventListener('scroll', updateScrollPosition, {passive: true});        
    }, []);

    return (
        <>
            <div className={ !props.whiteNavBar ? "navbar" : "navbar white-navbar" }>
                <div className="navbar-container">
                    <div className="navbar-content">
                        <div className="navbar-left desktop">
                            <a href="./" className="logo">
                                <img
                                    src={ !props.whiteNavBar ? openSkyLogoWhite : openSkyLogo }
                                    alt="OpenSky"
                                />
                            </a>                            
                            <span className={!props.whiteNavBar ? "benefit-name hide-lg-down" : props.gradient ? "benefit-name hide-lg-down gradient" : "benefit-name hide-lg-down"} >
                                OpenSky Visa&reg; Gold Card
                            </span>
                        </div>
                        <div className="navbar-left mobile">
                            <span className="onScroll">
                                <a href="./" className="logo">
                                    <img
                                        src={!props.whiteNavBar ? openSkyLogoWhite : openSkyLogo}
                                        alt="OpenSky"
                                    />
                                </a>
                            </span>
                        </div>
                        {!props.hideButton && (
                            <div className="navbar-right">
                                <span className="desktop">
                                    

                                    <button
                                        className="get-started gradient-border-transparent AcceptOfferButton"
                                        onClick={() => scrollToFormTop()}
                                    >
                                        Accept your offer today
                                    </button>
                                </span>
                                <span className="mobile">
                                    <span style={{display: 'flex'} }>
                                        <button
                                            className="get-started gradient-border-transparent"
                                            onClick={() => scrollToFormTop()}
                                        >
                                            Accept your offer today
                                        </button>
                                    </span>                                    
                                </span>                                
                            </div>
                        )}
                       
                    </div>
                    <div className="navbar-mobile-content">
                        <span className={!props.whiteNavBar ? "benefit-name" : props.gradient ? "benefit-name gradient" : "benefit-name"} >
                            OpenSky Visa&reg; Gold Card
                        </span>
                    </div>
                  
                </div>
            </div>
           
        </>
    )
}

export default NavBar