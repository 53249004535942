import React, { useRef } from 'react';
import NavBar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import './unauthorized.scss'

const Unauthorized = (props) => {

    const formTopRef = useRef();

    return (
        <>
            <div className='sticky'>
                <NavBar formTopRef={formTopRef} fieldValidationErrors={[]} hideButton={true} />
            </div>
            <section className="page unauthorized">
                <h2>We apologize for any inconvenience, however the OpenSky Visa&reg; Gold Card is available by invitation only.</h2>
                <p>We invite you to visit <a href="http://openskycc.com">openskycc.com</a> to learn more about OpenSky and apply for the OpenSky Secured Visa Credit Card.</p>
            </section>
                <Footer hideButton={true} />
            </>
    )
}

export default Unauthorized