import React, { useEffect, useState, useRef } from 'react';
import LineImage from '../../img/line.png';
import ProductDetailImage from '../../img/product-detail-card.webp';
import ProductCardBaseImage from '../../img/card-base.png';
import IconSafetyWhiteImage from '../../img/icon-safety-white.svg';
import CreditBureausImage from '../../img/credit-bureaus.svg';
import logoDesktop from '../../img/logo-d.png';
import logoMobile from '../../img/logo-m.png';
import openSkyLogoWhite from '../../img/logo-opensky-no-padding-white.svg';
import NumberFormat from 'react-number-format';
import AcceptForm from '../../components/acceptForm/acceptForm';
import OfferLocked from '../../components/offerLocked/offerLocked';
import MessageModal from '../../components/modals/messageModal';
import SessionTimer from '../../containers/sessionTimer/sessionTimer';
import * as FullStory from '@fullstory/browser';
import './middleSection.scss';

const MiddleSection = (props) => {


    const formTopRef = props.formTopRef;

    const scrollToFormTop = () => {
        console.log('middle scroll');
        FullStory.event('GoldTextAccept', {});
        formTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        formTopRef.current.focus();
    };

    

    return (
        <>            
            <div className="container middle-left">
                <div className="row">
                    {/*<div className="middle-section-approved">You&#39;re Approved for the OpenSky&reg; Visa&reg; Gold Card</div>*/}
                    <div className="middle-section-confirm">Confirm your identity</div>
                    <div className="middle-section-verify">Verify your identity for your protection in order to accept your offer.</div>
                </div>
            </div>
        </>
    )
}

export default MiddleSection;
