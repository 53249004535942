import React from 'react';
import CardFrontVectorImg from '../../img/intro-no-trademark.svg';
import CardShadow from '../../img/Card-Shadow.svg';
const CardImage = (props) => {
    return (
        <>
            <div className="container card-image">
                <div className="row">
                    <div className="text-center">
                        <img src={CardFrontVectorImg} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardImage;