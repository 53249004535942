import React, { useRef } from 'react';
import NavBar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import ThankYouImg from '../../img/thank-you-image-no-trademark.png';
import * as FullStory from '@fullstory/browser';
import './thankYou.scss'

const ThankYou = (props) => {

    const formTopRef = useRef();
    FullStory.event('GoldCardThankYou', {});
    return (
        <>
        <div className='sticky'>
                <NavBar formTopRef={formTopRef} fieldValidationErrors={[]} whiteNavBar={true} hideButton={true} />
        </div>
        <section className="page thankYou">
            <div className="text">
                <h2>Congratulations!</h2>
                <p>
                    Your OpenSky Visa&reg; Gold Card is being made just for you and will arrive in a
                    plain white envelope in 12-14 business days.
                </p>
                <p>
                    This is the next step in your journey towards
                    reaching your financial goals!
                </p>
            </div>
            <div>
                <img class="img" src={ThankYouImg} />
            </div>
            </section>
            <Footer hideButton={true} />
        </>
    )
}

export default ThankYou