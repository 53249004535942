import React, { useState, useContext } from "react";
import { Accordion, AccordionContext, useAccordionToggle } from "react-bootstrap";
import * as FullStory from '@fullstory/browser';

import './faq.css'

const FAQ = (props) => {
    const formTopRef = props.formTopRef;

    const scrollToFormTop = () => {
        console.log('faq scroll');
        FullStory.event('GoldFAQAccept', {});
        formTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        formTopRef.current.focus();
    };

    return (
        <section className="faq-section" Id="faq-section">
            <div className="faq-section-content">
                <h1 className="desktop-h3c">
                    Frequently Asked Questions
                </h1>
                <p className="intro-sub">Meet frequently answered answers.</p>
                <div className="tab-content faq-tab-content"
                    id="pills-tabContent">
                    <div className="tab-pane fade show active"
                        id="secured-creditcard"
                        role="tabpanel"
                        aria-labelledby="secured-creditcard-tab">
                        <div className="accordion" id="faqAccordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header"
                                    id="cat1-faq1-btn">
                                    <button className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#cat1-faq1-content"
                                        aria-expanded="false"
                                        aria-controls="cat1-faq1-content">
                                        What is the OpenSky Visa Gold Card?
                                    </button>
                                </h2>
                                <div id="cat1-faq1-content"
                                    className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <p>The OpenSky Visa Gold Credit Card is a new fully unsecured credit card, and is the perfect companion to your existing OpenSky Secured Card. It gives you more spending ability and flexibility to manage your expenses, while continuing to build your credit.</p>
                                        <p>Here are some great reasons to sign up for the new OpenSky Gold Card:</p>
                                        <ul>
                                            <li>Fully unsecured credit line with no deposit required</li>
                                            <li>More spending flexibility to manage your expenses</li>
                                            <li>An additional trade line that is reported </li>
                                            <li>Ability to continue to build your credit score through responsible use & behavior</li>
                                            <li>No credit check </li>
                                            <li>Reporting to all three credit bureaus </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header"
                                    id="cat1-faq2-btn">
                                    <button className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#cat1-faq2-content"
                                        aria-expanded="false"
                                        aria-controls="cat1-faq2-content">
                                        How can I sign up for the OpenSky Gold Card?
                                    </button>
                                </h2>
                                <div id="cat1-faq2-content"
                                    className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <p>The OpenSky Gold Card is currently available by invitation to existing OpenSky Secured Card customers only. To sign up for the card, you need to have received an invitation email.</p>
                                        <p>Please click on the link in the invitation email to get started. You will need to confirm your personal details and your existing OpenSky Secured Card details (so make you sure you have that handy).</p>
                                        <p>And remember, the OpenSky Gold Card invitation expires in 60 days from the date of your original invitation email. So, sign up now!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header"
                                    id="cat1-faq3-btn">
                                    <button className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#cat1-faq3-content"
                                        aria-expanded="false"
                                        aria-controls="cat1-faq3-content">
                                        Is there a credit check for this card?
                                    </button>
                                </h2>
                                <div id="cat1-faq3-content"
                                    className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <p>No, there is absolutely no credit check when you sign up for the OpenSky Gold Card.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header"
                                    id="cat1-faq4-btn">
                                    <button className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#cat1-faq4-content"
                                        aria-expanded="false"
                                        aria-controls="cat1-faq4-content">
                                        Am I already approved? What do I need to sign up?
                                    </button>
                                </h2>
                                <div id="cat1-faq4-content"
                                    className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <p>Yes, you are! All you need to do is confirm your identity via some verification questions, and accept the new OpenSky Gold Card terms & conditions. No credit check or lengthy applications to be filled out.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header"
                                    id="cat1-faq5-btn">
                                    <button className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#cat1-faq5-content"
                                        aria-expanded="false"
                                        aria-controls="cat1-faq5-content">
                                        Should I close my OpenSky Secured Card?
                                    </button>
                                </h2>
                                <div id="cat1-faq5-content"
                                    className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <p>The OpenSky Secured Card is a great way to continue building your credit. The OpenSky Gold Card and Secured Card together give you additional spending ability and flexibility to manage your expenses and achieve your financial goals.</p>
                                        <p>Keep in mind that your credit score is impacted by various factors, including, but not limited to, the length of your history, the type & amount of your credit lines & your utilization, and your payment history.</p>
                                        <p>Depending on the open date of your OpenSky Secured Card, it could be one of the longest credit histories you have reporting. Closing the card may adversely impact your credit score. It is best to ask a credit counselor expert for advice. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header"
                                    id="cat1-faq6-btn">
                                    <button className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#cat1-faq6-content"
                                        aria-expanded="false"
                                        aria-controls="cat1-faq6-content">
                                        Do I need to provide a security deposit?
                                    </button>
                                </h2>
                                <div id="cat1-faq6-content"
                                    className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <p>No, this is not a secured card, and no security deposit is required. You will receive a brand new card with its own credit limit.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header"
                                    id="cat1-faq7-btn">
                                    <button className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#cat1-faq7-content"
                                        aria-expanded="false"
                                        aria-controls="cat1-faq7-content">
                                        Will this affect my credit?
                                    </button>
                                </h2>
                                <div id="cat1-faq7-content"
                                    className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <p>There is no credit check when you sign up for the OpenSky Gold Card.</p>
                                        <p>The new Gold Card is the perfect companion to your existing OpenSky Secured Card. It gives you more spending ability and flexibility to manage your expenses, while continuing to build your credit. Now you can spend more and maintain the same utilization rate. </p>
                                        <p>Your credit score is impacted by various factors, including, but not limited to, the length of your history, the type & amount of your credit lines & your utilization, and your payment history.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header"
                                    id="cat1-faq8-btn">
                                    <button className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#cat1-faq8-content"
                                        aria-expanded="false"
                                        aria-controls="cat1-faq8-content">
                                        Will this card be reported to the credit bureaus?
                                    </button>
                                </h2>
                                <div id="cat1-faq8-content"
                                    className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <p>
                                            Yes, we will report your Gold Card to all three major credit bureaus. It will show up as a separate trade line on your credit report.
                                            And we will continue to report your existing Secured Card to the bureaus as well.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header"
                                    id="cat1-faq9-btn">
                                    <button className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#cat1-faq9-content"
                                        aria-expanded="false"
                                        aria-controls="cat1-faq9-content">
                                        Is there a fee for this card?
                                    </button>
                                </h2>
                                <div id="cat1-faq9-content"
                                    className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <p>
                                            The OpenSky Gold Card has an annual fee of $59. This fee will appear on your first monthly statement and every 12 months thereafter.
                                            For other fees and terms, please refer to your OpenSky Gold Card Agreement.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header"
                                    id="cat1-faq10-btn">
                                    <button className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#cat1-faq10-content"
                                        aria-expanded="false"
                                        aria-controls="cat1-faq10-content">
                                        How do I contact customer service?
                                    </button>
                                </h2>
                                <div id="cat1-faq10-content"
                                    className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <p>We are here for you 24x7 at 1-800-859-6412 to assist you with all your OpenSky accounts.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header"
                                    id="cat1-faq11-btn">
                                    <button className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#cat1-faq11-content"
                                        aria-expanded="false"
                                        aria-controls="cat1-faq11-content">
                                        What is OpenSky Rewards?
                                    </button>
                                </h2>
                                <div id="cat1-faq11-content"
                                    className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <p>OpenSky Rewards is a program that links offers from merchants directly to your enrolled card, allowing you to automatically earn cash back.
                                            Simply view your offers, use your enrolled card at participating merchants, and earn cash back.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header"
                                    id="cat1-faq12-btn">
                                    <button className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#cat1-faq12-content"
                                        aria-expanded="false"
                                        aria-controls="cat1-faq12-content">
                                        When will I receive my cash back?
                                    </button>
                                </h2>
                                <div id="cat1-faq12-content"
                                    className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <p>You will receive cash back typically within 120 days after the qualified transaction has been processed,
                                            and a statement credit will be automatically issued to your OpenSky Secured Visa®, OpenSky Plus Secured Visa, or OpenSky Visa Gold credit card.
                                            If you do not receive cash back within this timeframe, please submit an inquiry form in our rewards website,
                                            which can be accessed by logging into your account or mobile app and clicking on OpenSky Rewards.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQ