import React, { useState, useEffect } from 'react';
import SecurityApi from '../../api/securityApi';
import LoadingModal from '../modals/loadingModal';
import ContentLoading from '../contentLoading/contentLoading';
import { Redirect } from 'react-router';

const Redirector = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        if (props.action === 'redirectToFullTokenUrl') {
            redirectToFullTokenUrl();
        }
    }, []);

    const redirectToFullTokenUrl = () => {
        const urlPaths = window.location.pathname.split('/');
        if (urlPaths[1].toLowerCase() != 'token') {
            setIsLoading(false);
            return;
        }
        const tokenKey = urlPaths[2];
        const api = new SecurityApi();
        return api.getTokenFromKey(tokenKey)
            .then(response => {
                if (response.ok) {
                    response.json()
                        .then(token => {
                            setRedirectUrl(`/?token=${token}`);
                            setIsLoading(false);
                        });
                } else {
                    setRedirectUrl('/');
                    setIsLoading(false);
                }
            });
    }

    return (
        <>
            <LoadingModal showModal={isLoading} />
            <ContentLoading />
            {
                (redirectUrl != null) &&
                    <Redirect to={redirectUrl} />
            }
        </>
    );
};

export default Redirector;