import React, { useRef } from 'react';
import NavBar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import ProductDetailImage from '../../img/product-detail-card-no-trademark.svg';
import ProductCardBaseImage from '../../img/card-base.png';
import IconSafetyWhiteImage from '../../img/icon-safety-white.svg';
import CreditBureausImage from '../../img/credit-bureaus.svg';
import './contentLoading.scss'

const ContentLoading = (props) => {

    const formTopRef = useRef();

    return (
        <>
            <div className='sticky'>
                <NavBar formTopRef={formTopRef} fieldValidationErrors={[]} />
            </div>
            <section className="page">
                <section className="product-detail-section">
                    <div className="product-detail-section-content">
                        <img
                            src={ProductDetailImage}
                            alt=""
                            className="floating-card"
                        />
                        <div className="card-base-image">
                            <img src={ProductCardBaseImage} alt="" />
                        </div>
                        <div className="section-information">                    
                        </div>
                    </div>
                </section>
            </section>
            <Footer hideButton={true} formTopRef={formTopRef} />
        </>
    )
}

export default ContentLoading