import React from 'react';
import logoDesktop from '../img/logo-d.png';
import logoMobile from '../img/logo-m.png';
import Footer from '../components/footer/footer';
import './layout.scss'

const Layout = (props) => {

    return (
        <div className='wrapper'>
            {props.children}
        </div>
    );
};

export default Layout;