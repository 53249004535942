import BaseApi from './baseApi';

export default class OffersApi extends BaseApi {

    constructor() {
        super();
    }
        
    getOfferState() {
        return super.fetch(`./offers/getOfferState`, { method: 'GET' });
    }

    acceptOffer(payload) {
        return super.fetch(`./offers/acceptOffer`, { method: 'POST', body: JSON.stringify(payload) });
    }
}