import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Layout from './layouts/layout';
import Main from './containers/main/main';
import Redirector from './components/redirector/redirector';
import * as FullStory from '@fullstory/browser';

const App = () => {
    FullStory.init({ orgId: 'o-1CMRN3-na1' });
    const fsCurrentSession = FullStory.getCurrentSessionURL();
    

    return (
        <Switch>
            <Route exact path='/token/*' render={() => <Layout><Redirector action="redirectToFullTokenUrl" /></Layout>} />
            <Route exact path='/' render={() => <Layout><Main /></Layout>} />
            <Redirect to='/' />
        </Switch>
    );
};

export default App;