export default class BaseApi {

    constructor() {

        this.defaultOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        this.addTokenToHeader();
    }

    getTokenFromQueryString() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('token');
    }

    addTokenToHeader() {
        const token = this.getTokenFromQueryString();
        if (token != null) {
            this.defaultOptions.headers = {
                ...this.defaultOptions.headers,
                ...{ Authorization: "Bearer " + token }
            };
        }
    }

    fetch(uri, options) {
        const optionsObject = Object.assign({}, this.defaultOptions, options);
        return fetch(uri, optionsObject)
            .then(response => {
                return response;
            });
    }
}