import React, { useEffect, useState, useRef } from 'react';
import ProductDetail from '../../components/productDetail/productDetail';
import MiddleSection from '../../components/middleSection/middleSection';
import GoldCardDetail from '../../components/goldcarddetail/goldcarddetail';
import CardImage from '../../components/middleSection/CardImage';
import FAQ from '../../components/faq/faq';
import NavBar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import AcceptForm from '../../components/acceptForm/acceptForm';
import OfferLocked from '../../components/offerLocked/offerLocked';
import MessageModal from '../../components/modals/messageModal';
import SessionTimer from '../sessionTimer/sessionTimer';
import './product-detail-section.scss';
import './landing.scss';
import '../../components/acceptForm/react-datepicker.scss';
import CardBenefit from '../../components/comparisonTable/CardBenefit';
import CardComparisonTable from '../../components/comparisonTable/CardComparisonTable';

const Landing = (props) => {

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const formTopRef = useRef();

    window.addEventListener('resize', function (event) {
        setWidth(window.innerWidth);
       });

    useEffect(() => {
        
        var appForm = document.getElementById("appForm");
        var cardComparison = document.getElementById("cardComparison");
        if (width <= 960) {
            if (appForm.classList.contains("column-position-1")) {
                appForm.classList.remove("column-position-1");
            }
            if (!appForm.classList.contains("column-position-2")) {
                appForm.classList.add("column-position-2");
            }

            if (cardComparison.classList.contains("column-position-2")) {
                cardComparison.classList.remove("column-position-2");
            }
            if (!cardComparison.classList.contains("column-position-1")) {
                cardComparison.classList.add("column-position-1");
            }
        }
        else {
            if (appForm.classList.contains("column-position-2")) {
                appForm.classList.remove("column-position-2");
            }
            if (!appForm.classList.contains("column-position-1")) {
                appForm.classList.add("column-position-1");
            }

            if (cardComparison.classList.contains("column-position-1")) {
                cardComparison.classList.remove("column-position-1");
            }
            if (!cardComparison.classList.contains("column-position-2")) {
                cardComparison.classList.add("column-position-2");
            }
        }
    }, [setWidth, window.innerWidth]);

    useEffect(() => {
        if (props.fieldValidationErrors.length > 0) {
            scrollToFormTop();
            setShowErrorModal(true);
        }
    }, [props.fieldValidationErrors]);

    useEffect(() => {
        if (props.isOfferLocked === true) {
            scrollToFormTop();
        }
    }, [props.isOfferLocked]);

    const scrollToFormTop = () => {
        formTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        formTopRef.current.focus();
    };

    const renderApplicationForm = () => {
        if (props.isOfferLocked) {
            return <OfferLocked formTopRef={formTopRef} />
        } else {
            return (
                <SessionTimer timeoutMinutes="10">
                    <AcceptForm
                        firstName={props.customerData.firstName}
                        lastName={props.customerData.lastName}
                        emailAddress={props.customerData.emailAddress}
                        onFormSubmit={props.onFormSubmit}
                        formTopRef={formTopRef}
                    />
                </SessionTimer>
            );
        }
    };

    const handleModalClose = () => {
        setShowErrorModal(false);
    };

    const renderFieldValidationErrors = () => {
        if (showErrorModal) {
            let modalContent = props.fieldValidationErrors.map(error => {
                let fieldName = "";
                switch (error.memberNames[0]) {
                    case "Last4Ssn":
                        fieldName = "Last 4 Numbers of SSN";
                        break;
                    case "ExistingCards":
                        fieldName = "Last 6 digits of any open OpenSky card";
                        break;
                    case "DateOfBirth":
                        fieldName = "Date of Birth";
                        break;
                }
                return (
                    <div key={error.memberNames[0]}>
                        <div className="label" style={{ fontWeight: "bold" }}>{fieldName}:</div>
                        <p>{error.errorMessage}</p>
                        <br />
                    </div>
                );
            });

            return (
                <MessageModal
                    showModal={showErrorModal}
                    handleModalClose={handleModalClose}
                    showCloseButton="true"
                    title="Please correct the following errors.">
                    {modalContent}
                </MessageModal>
            );
        } else {
            return null;
        }
    };

    return (
        <>
            <div className='sticky'>
                <NavBar formTopRef={formTopRef} fieldValidationErrors={[]} whiteNavBar={true} hideButton={false} gradient={false} />
            </div>
            <section className="page">
                {renderFieldValidationErrors()}
                <section className="product-detail-section">
                    <ProductDetail formTopRef={formTopRef} {...props} />
                </section>
                <div className="container mt-5" id="middlesection-wrapper">
                    <div className="row">
                        <div className="col-lg-6 column-position-1" id="appForm">
                            {width > 960 && <div className="middle-section-approved">You&#39;re Approved for the OpenSky Visa&reg; Gold Card</div>}
                            <MiddleSection {...props} />
                            {renderApplicationForm()}
                        </div>
                        <div className="col-lg-6 column-position-2" id="cardComparison">
                            <div className='sticky-wrapper'>
                                {width > 960 && (<div id="cardImage">
                                                    <CardImage />
                                                </div>)}
                                <div>
                                    {width <= 960 && <div className="middle-section-approved">You&#39;re Approved for the OpenSky Visa&reg; Gold Card</div>}
                                    <GoldCardDetail/>
                                    <div className="" id="cardComparisonChart1">
                                        <CardComparisonTable />
                                        <CardBenefit />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <input type="text" style={{ opacity: 0 }} />
                <FAQ formTopRef={formTopRef} {...props} />
            </section>
            <Footer hideButton={false} formTopRef={formTopRef} />
        </>
    );
};

export default Landing;