import React from 'react';
import './offerLocked.scss'

const OfferLocked = (props) => {
    return (
        <>
            <span ref={props.formTopRef}></span>
            <div className="offerLocked">
                <h2>Oops, it looks like something went wrong</h2>
                <p>You have exceeded the maximum number of login attempts due to the information entered not matching up with your account information on file.</p>
                <p>We're sorry for the inconvenience but we cannot provide you the OpenSky Gold card at this time. Please call us at <strong>1-800-859-6412</strong> to verify your account information for future offers.</p>
            </div>
        </>
    );
};

export default OfferLocked;