import React, { useRef } from 'react';
import NavBar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import LineImage from '../../img/line.png';
import * as FullStory from '@fullstory/browser';
import './expired.scss'

const Expired = (props) => {

    const formTopRef = useRef();
    FullStory.event('GoldExpiredOffer', {});
    return (
        <>
        <div className='sticky'>
            <NavBar formTopRef={formTopRef} fieldValidationErrors={[]} />
        </div>
        <section className="page expired">
            <h2>Thank you for being a valued OpenSky customer</h2>
            <p>
                <b>Your offer for the OpenSky Visa&reg; Gold Card has expired.</b><br />
                We apologize for any inconvenience.  If you have any questions, please call OpenSky Customer Service.
            </p>
            </section>
            <Footer hideButton={true} />
        </>
    )
}

export default Expired