import React from 'react';
import Modal from 'react-bootstrap/Modal';
import LoadingImage from '../../img/loading.gif';
import './loadingModal.scss';

const LoadingModal = (props) => {
    return (
        <Modal
            show={props.showModal}
            centered
            backdrop="static">
            <Modal.Body>
                <img src={LoadingImage} />
            </Modal.Body>
        </Modal>
  );
};

export default LoadingModal;