import React, { useEffect, useState, useRef } from 'react';

const ComparisonDetail = (props) => {
    return (
        <div className="comparisonDetailOuter">
            <div className="comparisonDetail">
                {props.name}
            </div>
        </div>
    )
}

export default ComparisonDetail;