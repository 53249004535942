import BaseApi from './baseApi';

export default class SecurityApi extends BaseApi {

    constructor() {
        super();
    }

    getCustomerData() {
        return super.fetch(`./security/getCustomerData`, { method: 'GET' });
    }

    getTokenFromKey(tokenKey) {
        return super.fetch(`./security/getTokenFromKey/${tokenKey}`, { method: 'GET' });
    }
}